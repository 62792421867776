@use '../../../styles/library' as *;

.user {
  $width: calc($baseSizeN / 17 * 6.5rem);
  z-index: -10;
  position: absolute;
  left: -#{$width};
  bottom: 0;
  width: $width;
  color: $second-clr-500; 
}