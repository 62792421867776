@use '../../../styles/library' as *;

.station-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2em;

  font-size: 1rem;

  transition: all .4s;

  & > div {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: .5em;
  }
  button, textarea {
    border-radius: .3rem;
    outline: none;
    font-size: 1em;
  }
  button {
    padding: .75em 1.5em;
    border: none;
    background-color: $main-clr-800;
    color: $second-clr-50;
    align-self: center;
    
    &:hover {
      cursor: pointer;
      filter: brightness(95%);
    }
  }
  textarea {
    padding: .75em;
    resize: vertical;
    width: 100%;
    min-height: 3rem;
    max-height: 15rem;
    border: 2px solid $main-clr-800;
    font-family: inherit;
  }
}