@use '../../styles/library' as *;

.modal {
  z-index: 10000;
  position: fixed;
  top: 3em;
  left: 50%;
  transform: translateX(-50%);
  max-width: 90%;
  max-height: 90%;
  
  border: 2px solid $main-clr-900;
  border-radius: .5rem;
  background-color: $second-clr-50;

  display: flex;
  flex-direction: column;
  
  &-title {
    border-bottom: inherit;

    color: $second-clr-800;
    text-align: center;
    font-weight: 500;
    font-size: 1.5em;
    letter-spacing: .5px;
    padding: 1em 1.5em;
  }
  &-content {
    padding: 1.5em 1.5em;
    overflow-y: auto;
  }
}