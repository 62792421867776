@use '../../../styles/library' as *;

.user-form-modal {
  max-width: max(30rem, 50%);
  min-width: fit-content;
  width: 50%;
  form {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1em 1.5em;
    font-size: 0.95em;

    label, input {
      font-size: 1.15em;
    }
    input {
      padding: 0.75em;
      border-radius: 0.5rem;
      border: 2px solid $main-clr-900;
    }
    
    button:hover {
      cursor: pointer;
      filter: brightness(95%);
    }

    button[type="submit"] {
      grid-column: 1 / -1;
      justify-self: center;
      
      margin-top: 1em;
      padding: 1em 1.25em;

      background-color: $main-clr-700;
      color: $second-clr-50;
      font-size: 1.2em;
      letter-spacing: 0.5px;
      border: none;
      border-radius: 0.5rem;
    }
  }
}