@use '../../../styles/library' as *;

.station {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: calc(-2 * $trackHeight);
  width: 100%;
  font-size: calc($baseSize / 17);
  overflow: hidden;

  .station-wrapper {
    position: relative;
    width: calc($baseSize * 2);
  }
}
.station-modal {
  width: 80%;
  max-width: 50rem;
  .modal-content {
    padding: 1em;
    & > *:not(:last-child) {
      padding-bottom: 1.5em;
      border-bottom: 2px solid $main-clr-900;
      margin-bottom: 1.5em;
    }
    .station-summary-list {
      display: flex;
      flex-direction: column;
      padding: 1em;
      & > li {
        padding: 0;
        & > span {
          display: block;
          font-size: 1.5em;
          margin-bottom: .5em;
        }
        & > ul {
          display: flex;
          flex-wrap: wrap;
          gap: .75em;
          li {
            font-size: 1.25em;
            padding: 0.5em 0.75em;
            border-radius: 0.5rem;
          }
        }
        &:not(:first-child) {
          padding-top: 1em;
        }
        &:not(:last-child) {
          border-bottom: 2px solid $main-clr-900;
          padding-bottom: 1em;
        }
      }
      li {
        list-style: none;
      }
    }
    .button {
      display: block;
      margin: 0 auto;

      padding: 0.5em 1em;
      border: none;
      background-color: $main-clr-600;
      border-radius: 0.5rem;

      font-size: 1.3em;
      color: $second-clr-100;
      letter-spacing: 0.5px;
      &:hover {
        cursor: pointer;
        filter: brightness(95%);
      }
    }
  } 
}