$main-clr-600: #ea580c;
$main-clr-700: #c2410c;
$main-clr-800: #9a3412;
$main-clr-900: #7c2d12;
$main-clr-950: #431407;

$second-clr-50: #fafafa;
$second-clr-100: #f5f5f5;
$second-clr-200: #e5e5e5;
$second-clr-300: #d4d4d4;
$second-clr-400: #a3a3a3;
$second-clr-500: #737373;
$second-clr-600: #525252;
$second-clr-700: #404040;
$second-clr-800: #262626;
$second-clr-900: #171717;
$second-clr-950: #0a0a0a;


$green-clr-600: #16a34a;
$green-clr-700: #15803d;

$sky-clr-300: #7dd3fc;
$sky-clr-400: #38bdf8;
$sky-clr-500: #0ea5e9;