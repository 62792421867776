@use '../../../styles/library' as *;

.carriage {
  position: relative;
  z-index: 1000;
  font-size: calc($baseSize / 17);
  padding: 0 calc($trainGap / 1) 0 calc($trainGap / 2);
  overflow: hidden;
  max-width: min-content;
  
  .items {
    position: relative;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    gap: .2px;
  }
  object {
    width: $baseSize;
  }
  span {
    position: absolute;
    bottom: 3.8em;
    left: 50%;
    width: max-content;
    max-width: calc(100% - $trainGap - 1em);
    transform: translate(-50%);

    text-align: center;
    color: $second-clr-800;
    letter-spacing: .5px;
    font-size: 1.25em;
    font-weight: 500;
  }
  &::before, &::after {
    content: '';
    position: absolute;
    z-index: -10;
    left: 0;
    bottom: 4em;
    width: 100%;
  }
  &::before {
    height: .5rem;
    background-color: $second-clr-500;
  }
  &::after {
    height: $hookHeight;
    transform: translateY(calc($hookHeight / 3));
    background-color: $hookColor;
    background: linear-gradient(to left, $hookColor $hookWidth, transparent $hookWidth, transparent);
  }
}