@use '../../styles/library' as *;

#template-content {
  display: none;

  z-index: 1000;
  position: fixed;
  top: -200%;
  left: 0;
  width: 750px;
  padding: 1em;
  h1 {
    font-size: 2.5em;
  }
  & > ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
    & > li {
      margin-top: 1em;
      border-top: 2px solid $main-clr-900;
      padding-top: 1em;
      & > span {
        font-size: 1.5em;
        display: inline-block;
        margin-bottom: 0.75em;
        padding-bottom: 0.2em;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;
        li {
          font-size: 1.15em;
          padding: 0.5em 0.75em;
          border-radius: 0.5rem;
        }
      }
    }
    li {
      list-style: none;
    }
  }
}
