@use '../../../styles/library' as *;

.background {
  --offset: 0rem;
  
  position: absolute;
  bottom: calc(-20vw - 1rem - var(--offset));
  left: -250%;
  width: 1000%;
  transform: translateX(-50%);;
  height: 100%;
  .layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;


    background-image: url('../../../../public/assets/background.svg');
    background-size: 2.5%;
    background-position: 0 100%;
    background-repeat: repeat-x;
  }
  @media screen and (min-width: 90rem) {
    --offset: 2rem;
  }
}