@use '../../../styles/library' as *;

.station-buttons {
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  list-style: none;
  li button {
    font-size: 1em;
    padding: .5em 1em;
    background-color: $second-clr-50;
    color: $second-clr-950;
    border: none;
    border-radius: .3rem;
    &:hover {
      cursor: pointer;
      filter: brightness(95%);
    }
  }
}