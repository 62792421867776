@use './styles/library.scss' as *;

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  min-height: 100vh;
}
body {
  font-family: 'Lato', 'Roboto', sans-serif;
}

#root {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: $sky-clr-400;
}