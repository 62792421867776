@use 'colors' as *;
@forward 'colors';

@media screen and (max-width: 40rem) {
  body {
    font-size: 0.9rem;
  }
}

$baseSizeN: 17;
$baseSize: calc($baseSizeN * 1rem);
$trackHeight: 0.3125rem;
$trainGap: 1em;
$hookColor: #{$second-clr-600};
$hookWidth: 0.75em;
$hookHeight: 1.5em;

