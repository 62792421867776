@use '../../styles/library' as *;

.content-wrapper {
  position: absolute;
  left: 0rem;
  padding: 0 12.5rem;
  bottom: 0;
  min-width: 100%;
  width: max-content;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}