
.carriage-item {
  $borderWidth: 2px;
  position: relative;
  width: 3rem;
  aspect-ratio: 1.5 / 1;
  padding: .5em;
  border-radius: .5rem;
  background-color: inherit;
  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: inherit;
    border-radius: inherit;
  }
  &::before {
    z-index: 10;
    width: calc(100% - $borderWidth);
    height: calc(100% - $borderWidth);
  }
  &::after {
    z-index: 1;
    width: 100%;
    height: 100%;
    filter: brightness(80%);
  }
}