@use '../../../styles/library' as *;

.train {
  position: relative;
  z-index: 1000;
  padding-right: $trainGap;
  font-size: calc($baseSize / 17);
  
  & > object {
    width: $baseSize;
  }
  &::before, &::after {
    content: '';
    position: absolute;
    z-index: -10;
    right: 0;
    bottom: 4em;
    width: 50%;
  }
  &::before {
    height: .5rem;
    background-color: $second-clr-500;
  }
  &::after {
    height: $hookHeight;
    transform: translateY(calc($hookHeight / 3));
    background-color: $hookColor;
    background: linear-gradient(to left, $hookColor $hookWidth, transparent $hookWidth, transparent);
  }
}

