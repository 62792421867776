@use '../../styles/library' as *;


.tree {
  --height: 75%;
  --width: 3rem;
  --brightness: 100%;
  
  position: absolute;
  left: -100%;
  bottom: calc($trackHeight * -2);
  height: var(--height);
  width: var(--width);
  background-color: $main-clr-900;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 700%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1 / 1;
    background-color: $green-clr-700;
    border-radius: 50%;
    filter: brightness(var(--brightness));
  }
}