@use '../../../styles/library.scss' as *;

.track {
  $width: calc(150% / (300 * 17 / $baseSizeN));
  $trackMetalColor: #{$second-clr-400};
  $trackPlanksColor: #{$main-clr-900};

  position: absolute;
  z-index: 100;
  bottom: 0;
  left: -250%;
  width: 1000%;
  height: $trackHeight;
  background: repeating-linear-gradient(to right, $trackPlanksColor, $trackPlanksColor 50%, transparent 50%, transparent);
  background-size: $width 100%;
  &::before {
    content: '';
    position: absolute;
    top: -$trackHeight;
    left: 0;
    width: inherit;
    height: $trackHeight;
    background-color: $trackMetalColor;
  }
}