@use '../../styles/library.scss' as *;

.trees {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  height: 100%;
  max-height: 60rem;
  width: 100%;
  overflow: hidden;
}